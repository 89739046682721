import { VaccinationActApiInterface as ProVaccinationActApiInterface } from "@syadem/kairos-pro-js";
import { VaccinationActApiInterface as TeamVaccinationActApiInterface } from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { Service } from "./service";
import { ActionResult } from "../actionResult";

interface Payload {
  organizationId: string | null;
  teamId: string | null;
  healthRecordId: string;
  vaccinationActId: string;
}

export class DeleteVaccinationActService implements Service<Payload, void> {
  constructor(
    private readonly proVaccinationactapi: ProVaccinationActApiInterface,
    private readonly teamVaccinationactapi: TeamVaccinationActApiInterface,
    private readonly store: AppStore,
  ) {}

  async call({ vaccinationActId, healthRecordId, organizationId, teamId }: Payload): Promise<ActionResult<void>> {
    if (organizationId && teamId) {
      await this.teamVaccinationactapi.deleteVaccinationAct(organizationId, teamId, healthRecordId, vaccinationActId);
    } else {
      await this.proVaccinationactapi.deleteVaccinationAct(healthRecordId, vaccinationActId);
    }

    this.removeVaccinationActFromStore(healthRecordId, vaccinationActId);

    return {
      type: "success",
      value: undefined,
    };
  }

  private removeVaccinationActFromStore(healthRecordId: string, vaccinationActId: string) {
    this.store.setState((state) => {
      if (state.healthRecords && state.healthRecords[healthRecordId]) {
        state.healthRecords[healthRecordId].vaccinationActs = state.healthRecords[
          healthRecordId
        ].vaccinationActs.filter((va) => va.id === vaccinationActId);
      }
      return state;
    });
  }
}
