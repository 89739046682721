import { Box, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useMemo, useState } from "react";
import { Asserts } from "yup";
import { TranslateFunction, createTranslateFn } from "../../utils/formUtils";
import { getTranslatedFieldByLocale } from "../../utils/translatedFields";
import { getVaccinationSchema } from "../../utils/vaccinationActs";
import { useI18n } from "../hooks/useI18n";
import { useVaccineWithDiseases } from "../hooks/useVaccineWithDiseases";
import { VaccineWithDiseases, useVaccinesWithDiseases } from "../hooks/useVaccinesWithDiseases";
import StyledAutoComplete from "./mui/StyledAutoComplete";

const FormVaccinesAutocomplete = ({ readOnly = false }: { readOnly?: boolean }) => {
  const { t, locale } = useI18n();
  const _vaccinationSchema = getVaccinationSchema();
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext<Asserts<typeof _vaccinationSchema>>();
  const [vaccineInput, setVaccineInput] = useState<string>("");
  const translateErrors: TranslateFunction = useMemo(() => createTranslateFn(t), [t]);

  const { vaccinesWithDiseases, isLoading: isLoadingVaccinesWithDiseases } = useVaccinesWithDiseases(
    vaccineInput,
    values.performedOn,
  );

  const defaultVaccine = useVaccineWithDiseases(values.vaccineId);

  function renderVaccine(vaccineWithDiseases: VaccineWithDiseases) {
    const vaccine = vaccineWithDiseases.vaccine;
    return (
      <Box>
        <Typography fontWeight="bold" fontSize="0.9rem">
          {getTranslatedFieldByLocale(locale, vaccine.name)}
        </Typography>
        <Typography fontSize="0.8rem">
          {vaccineWithDiseases.diseases.map((d) => getTranslatedFieldByLocale(locale, d.name)).join(", ")}
        </Typography>
        {vaccineWithDiseases.distributed && vaccineWithDiseases.distribution?.startsOn && (
          <Typography fontSize="0.75rem">
            {vaccineWithDiseases.distribution?.endsOn
              ? t("vaccines.distributedBetween", {
                  distributionStart: vaccineWithDiseases.distribution.startsOn.toLocaleDateString(
                    locale === "en-gb" ? "en" : locale,
                  ),
                  distributionEnd: vaccineWithDiseases.distribution.endsOn.toLocaleDateString(
                    locale === "en-gb" ? "en" : locale,
                  ),
                })
              : t("vaccines.distributedSince", {
                  distributionStart: vaccineWithDiseases.distribution.startsOn.toLocaleDateString(
                    locale === "en-gb" ? "en" : locale,
                  ),
                })}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <StyledAutoComplete
      id="vaccineId"
      noOptionsText={t("common.errors.noResults")}
      error={touched.vaccineId && !!errors.vaccineId}
      errorMessage={errors.vaccineId}
      touched={touched.vaccineId}
      translateErrors={translateErrors}
      fullWidth
      label={t("vaccines.name_or_disease")}
      placeholder={t("vaccines.placeholder")}
      required
      loading={isLoadingVaccinesWithDiseases}
      options={vaccinesWithDiseases}
      readOnly={readOnly}
      getOptionLabel={(vaccineWithDiseases) => vaccineWithDiseases.vaccineNameWithDiseases}
      groupBy={(option) => (option.distributed ? t("vaccines.vaccinesDistributed") : t("vaccines.otherVaccines"))}
      filterOptions={(options) => options}
      renderGroup={(params) => {
        return (
          <li key={params.key} className="group">
            <Box
              sx={{
                position: "sticky",
                top: "-8px",
                padding: "8px 10px",
                color: "rgba(0,0,0,0.6)",
                fontSize: "14px",
                backgroundColor: "white",
              }}
            >
              {params.group}
            </Box>
            <Box component="ul" sx={{ p: 0 }}>
              {params.children}
            </Box>
          </li>
        );
      }}
      renderOption={(props, vaccineWithDisease) => (
        <li {...props} key={vaccineWithDisease.vaccine.id}>
          {renderVaccine(vaccineWithDisease)}
        </li>
      )}
      value={defaultVaccine ?? null}
      onInputChange={(_e, value) => setVaccineInput(value)}
      onChange={(_event: React.SyntheticEvent<Element, Event>, newVaccineWithDiseases) => {
        setFieldTouched("vaccineId", true);
        setFieldValue("vaccineId", newVaccineWithDiseases?.vaccine?.id ?? undefined);
        if (!newVaccineWithDiseases) {
          setFieldValue("vaccineId", "");
        }
      }}
    />
  );
};

export default FormVaccinesAutocomplete;
