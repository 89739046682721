import { useContext } from "react";
import { ProcessDatamatrixCode } from "../../utils/datamatrix";
import { DependenciesContext } from "../providers/Dependencies";

export function useProcessDatamatrixCode(): ProcessDatamatrixCode {
  const dependencies = useContext(DependenciesContext);
  if (!dependencies) {
    throw new Error("Dependencies container is null, did you forget to pass it to the provider?");
  } else {
    return dependencies.processDatamatrixCode;
  }
}
