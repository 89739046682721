import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Typography } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { isSharedToPatient } from "../../../utils/healthRecord";
import { useFetchHealthRecord } from "../../hooks/useFetchHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { GrantOwnershipForm } from "./share/GrantOwnershipForm";
import { ShareWithProForm } from "./share/ShareWithProForm";
import { useHealthRecord } from "../../../store";

export const Share = () => {
  const { t } = useI18n();
  const { pathname } = useLocation();
  const { id } = useParams() as { id: string };
  const { refetch } = useFetchHealthRecord(id);
  const healthRecord = useHealthRecord(id);

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Box sx={{ mb: 2 }}>
        <Button
          startIcon={<ArrowBackIcon sx={{ width: 24, height: 24 }} />}
          size="large"
          sx={{ pl: 0 }}
          component={Link}
          to={`${pathname.substring(0, pathname.lastIndexOf("/share"))}/shares`}
        >
          <Typography variant="h6">{t("common.cta.back")}</Typography>
        </Button>
      </Box>
      {healthRecord && !isSharedToPatient(healthRecord) && <GrantOwnershipForm healthRecordId={id} />}
      <ShareWithProForm onSubmitSuccessful={refetch} />
    </Box>
  );
};
