import {
  VaccinationActApiInterface as ProVaccinationActApiInterface,
  VaccinationAct as ProVaccinationAct,
} from "@syadem/kairos-pro-js";
import {
  VaccinationActApiInterface as TeamVaccinationActApiInterface,
  VaccinationAct as TeamVaccinationAct,
} from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { Service } from "./service";
import { ActionResult } from "../actionResult";

interface Payload {
  vaccinationAct: {
    vaccineId: string;
    countryCode: string;
    performedOn: Date;
    expirationDate: Date | undefined;
    batchNumber: string;
    serialNumber: string;
    injectionLocation: string;
    injectionMethod: string;
    certificationMethod: "execution" | "proof" | "not_validated";
    booster: boolean;
  };
  organizationId: string | null;
  teamId: string | null;
  healthRecordId: string;
  vaccinationActId: string;
}

export class UpdateVaccinationActService implements Service<Payload, ProVaccinationAct | TeamVaccinationAct> {
  constructor(
    private readonly proVaccinationactapi: ProVaccinationActApiInterface,
    private readonly teamVaccinationactapi: TeamVaccinationActApiInterface,
    private readonly store: AppStore,
  ) {}

  async call({
    vaccinationAct,
    vaccinationActId,
    healthRecordId,
    organizationId,
    teamId,
  }: Payload): Promise<ActionResult<ProVaccinationAct | TeamVaccinationAct>> {
    let apiVaccinationAct: ProVaccinationAct | TeamVaccinationAct;

    if (organizationId && teamId) {
      apiVaccinationAct = (
        await this.teamVaccinationactapi.updateVaccinationAct(
          organizationId,
          teamId,
          healthRecordId,
          vaccinationActId,
          { vaccinationAct },
        )
      ).vaccinationAct;
    } else {
      apiVaccinationAct = (
        await this.proVaccinationactapi.updateVaccinationAct(healthRecordId, vaccinationActId, { vaccinationAct })
      ).vaccinationAct;
    }

    this.updateVaccinationActInStore(healthRecordId, apiVaccinationAct);

    return {
      type: "success",
      value: apiVaccinationAct,
    };
  }

  private updateVaccinationActInStore(healthRecordId: string, vaccinationAct: ProVaccinationAct | TeamVaccinationAct) {
    this.store.setState((state) => {
      if (state.healthRecords && state.healthRecords[healthRecordId]) {
        const vaccinationActToUpdateIndex = state.healthRecords[healthRecordId].vaccinationActs.findIndex(
          (va) => va.id === vaccinationAct.id,
        );
        state.healthRecords[healthRecordId].vaccinationActs[vaccinationActToUpdateIndex] = vaccinationAct;
      }
      return state;
    });
  }
}
