import { HealthRecordApi } from "@syadem/kairos-pro-js";
import { AppStore } from "../store";

export class ProHealthRecordQuery {
  constructor(
    private readonly store: AppStore,
    private readonly healthRecordApi: HealthRecordApi,
  ) {}

  async call(id: string) {
    const healthRecord = (await this.healthRecordApi.getHealthRecord(id)).healthRecord;

    this.store.setState((state) => {
      if (state.healthRecords) {
        state.healthRecords[healthRecord.id] = healthRecord;
      } else {
        state.healthRecords = { [healthRecord.id]: healthRecord };
      }
      if (state.proHealthRecordIds === undefined) {
        state.proHealthRecordIds = [healthRecord.id];
      } else if (!state.proHealthRecordIds.includes(healthRecord.id)) {
        state.proHealthRecordIds.push(healthRecord.id);
      }

      return state;
    });
  }
}
