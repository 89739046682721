import {
  VaccinationActApiInterface as ProVaccinationActApiInterface,
  VaccinationAct as ProVaccinationAct,
} from "@syadem/kairos-pro-js";
import {
  VaccinationActApiInterface as TeamVaccinationActApiInterface,
  VaccinationAct as TeamVaccinationAct,
} from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { Service } from "./service";
import { ActionResult } from "../actionResult";

interface Payload {
  vaccinationAct: {
    vaccineId: string;
    countryCode: string;
    performedOn: Date;
    expirationDate?: Date;
    batchNumber: string;
    serialNumber: string;
    injectionLocation: string;
    injectionMethod: string;
    certificationMethod: "execution" | "proof" | "not_validated";
    booster: boolean;
  };
  organizationId: string | null;
  teamId: string | null;
  healthRecordId: string;
}

export class CreateVaccinationActService implements Service<Payload, ProVaccinationAct | TeamVaccinationAct> {
  constructor(
    private readonly proVaccinationactapi: ProVaccinationActApiInterface,
    private readonly teamVaccinationactapi: TeamVaccinationActApiInterface,
    private readonly store: AppStore,
  ) {}

  async call({
    vaccinationAct,
    healthRecordId,
    organizationId,
    teamId,
  }: Payload): Promise<ActionResult<ProVaccinationAct | TeamVaccinationAct>> {
    let apiVaccinationAct: ProVaccinationAct | TeamVaccinationAct;

    if (organizationId && teamId) {
      apiVaccinationAct = (
        await this.teamVaccinationactapi.createVaccinationAct(organizationId, teamId, healthRecordId, {
          vaccinationAct,
        })
      ).vaccinationAct;
    } else {
      apiVaccinationAct = (await this.proVaccinationactapi.createVaccinationAct(healthRecordId, { vaccinationAct }))
        .vaccinationAct;
    }

    this.addVaccinationActInStore(healthRecordId, apiVaccinationAct);

    return {
      type: "success",
      value: apiVaccinationAct,
    };
  }

  private addVaccinationActInStore(healthRecordId: string, vaccinationAct: ProVaccinationAct | TeamVaccinationAct) {
    this.store.setState((state) =>
      state.healthRecords && state.healthRecords[healthRecordId]
        ? {
            healthRecords: {
              ...state.healthRecords,
              [healthRecordId]: {
                ...state.healthRecords[healthRecordId],
                vaccinationActs: [vaccinationAct, ...state.healthRecords[healthRecordId].vaccinationActs],
              },
            },
          }
        : state,
    );
  }
}
