import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Snackbar,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { CertificationMethodEnum } from "@syadem/kairos-pro-js";
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType, NotFoundException, Result } from "@zxing/library";
import { useFormikContext } from "formik";
import { RefObject, useEffect, useRef, useState } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { Asserts } from "yup";
import { ScanResult } from "../../utils/datamatrix";
import { getVaccinationSchema } from "../../utils/vaccinationActs";
import { useI18n } from "../hooks/useI18n";
import { useProcessDatamatrixCode } from "../hooks/useProcessorDatamatrix";
import { theme } from "../layout/Theme";
import StyledDialog from "./mui/StyledDialog";
import { StyledSelect } from "./mui/StyledSelect";

const hints = new Map();
const TIME_BETWEEN_SCANS_MS = 150;
const formats = [BarcodeFormat.DATA_MATRIX];
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
hints.set(DecodeHintType.TRY_HARDER, true);
const codeReader = new BrowserMultiFormatReader(hints, TIME_BETWEEN_SCANS_MS);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const BarCodePanel = ({ onScanSuccess }: { onScanSuccess: (scanResult: ScanResult) => void }) => {
  const { t, getObject } = useI18n();
  const [error, setError] = useState<string | null>(null);
  const processDatamatrixCode = useProcessDatamatrixCode();
  const [datamatrix, setDatamatrix] = useState("");

  const handleOnEnter = (code: string) => {
    try {
      setError(null);
      const result: ScanResult = processDatamatrixCode(code, t);
      if (result && onScanSuccess) {
        onScanSuccess(result);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  // Hack to focus on input with ReactStrictMode (MUI Issue)
  const refInput: RefObject<HTMLInputElement | null> = useRef(null);
  useEffect(() => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  });

  return (
    <Box>
      <TextField
        fullWidth
        name="code"
        type="text"
        variant="outlined"
        data-testid="barCodeScannerInput"
        onChange={(e) => setDatamatrix(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Tab") {
            handleOnEnter(datamatrix.trim());
          }
        }}
        autoFocus
        inputRef={refInput}
        size="small"
      />
      {error ? (
        <Alert severity="error" sx={{ mt: 3 }}>
          {error}
        </Alert>
      ) : (
        <Paper
          elevation={0}
          sx={{
            background: theme.palette.neutral[100],
            border: `solid 1px ${theme.palette.neutral[200]}`,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 3,
          }}
        >
          <List>
            {Object.entries(getObject("datamatrix.barCodeScanner.steps")).map(([key, value]) => (
              <ListItem key={`stepIndex${key}`}>
                <ListItemAvatar sx={{ minWidth: 40 }}>
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      color: theme.palette.neutral[600],
                      backgroundColor: theme.palette.neutral[300],
                    }}
                  >
                    <Typography variant="subtitle2">{key}</Typography>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={value} sx={{ ".MuiListItemText-primary": { fontWeight: "400" } }} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
};

const CameraPanel = ({ onScanSuccess }: { onScanSuccess: (scanResult: ScanResult) => void }) => {
  const { t, getObject } = useI18n();

  const [devices, setDevices] = useState<MediaDeviceInfo[] | undefined>(undefined);
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | undefined>(undefined);

  const [error, setError] = useState<string | null>(null);

  const breakpointMd = useMediaQuery(theme.breakpoints.down("md"));
  const [inverseCamera, setInverseCamera] = useState(breakpointMd);
  const processDatamatrixCode = useProcessDatamatrixCode();

  useEffect(() => {
    (async () => {
      if (!codeReader) return;
      const devices = await codeReader.listVideoInputDevices();
      setDevices(devices);
    })();
  }, [setDevices]);

  useEffect(() => {
    let isCancelled = false;
    if (codeReader && devices) {
      if (!isCancelled) {
        codeReader.decodeFromConstraints(
          {
            video: {
              facingMode: {
                ideal: "environment" /* give priority to back camera on phones */,
              },
              frameRate: { ideal: 30 },
              width: { ideal: 1024 },
              height: { ideal: 1024 },
              deviceId: selectedDevice?.deviceId,
            },
            audio: false,
          },
          "video",
          async (camScanResult: Result, err) => {
            if (camScanResult) {
              setError(null);
              const result: ScanResult = processDatamatrixCode(camScanResult, t);
              if (result && onScanSuccess) {
                onScanSuccess(result);
              }
            }

            if (err && !(err instanceof NotFoundException)) {
              return setError("datamatrix.scanError");
            }
          },
        );
      }
    }
    return () => {
      isCancelled = true;
      codeReader.reset();
    };
  }, [t, devices, selectedDevice, onScanSuccess, processDatamatrixCode]);

  return (
    <Box>
      {devices === undefined ? (
        <CircularProgress />
      ) : devices.length > 0 ? (
        <>
          <DeviceSelector
            onChangeCallback={(e) => {
              if (typeof e.target.value !== "number" || e.target.value < 0) {
                setSelectedDevice(undefined);
              } else {
                setSelectedDevice(devices[e.target.value]);
              }
            }}
            devices={devices}
          />
          <Box
            sx={{
              marginY: 2,
              position: "relative",
              height: "400px",
              width: "100%",
              ...(!inverseCamera && { transform: "rotateY(180deg)" }),
              "&:after": {
                content: '""',
                width: "50%",
                height: "50%",
                border: `3px solid ${theme.palette.warning[500]}`,
                background: "rgba(255, 215, 56, 0.25)",
                display: "block",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
              },
            }}
          >
            <video id="video" height="100%" width="100%"></video>
          </Box>
          {error ? (
            <Alert severity="error" sx={{ mt: 3 }}>
              {error}
            </Alert>
          ) : (
            <>
              <FormControlLabel
                control={<Switch checked={inverseCamera} onChange={() => setInverseCamera(!inverseCamera)} />}
                label={<Typography variant="subtitle2">{t("datamatrix.inverseImage")}</Typography>}
              />
              <Paper
                elevation={0}
                sx={{
                  background: theme.palette.neutral[100],
                  border: `solid 1px ${theme.palette.neutral[200]}`,
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 3,
                }}
              >
                <List>
                  {Object.entries(getObject("datamatrix.camera.steps")).map(([key, value]) => (
                    <ListItem key={`stepIndex${key}`}>
                      <ListItemAvatar sx={{ minWidth: 40 }}>
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            color: theme.palette.neutral[600],
                            backgroundColor: theme.palette.neutral[300],
                          }}
                        >
                          <Typography variant="subtitle2">{key}</Typography>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={value} sx={{ ".MuiListItemText-primary": { fontWeight: "400" } }} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </>
          )}
        </>
      ) : (
        <Alert severity="error">{t("datamatrix.noDeviceError")}</Alert>
      )}
    </Box>
  );
};

const ScanCodeDialog = ({
  open,
  onClose,
  onScanSuccess,
}: {
  open: boolean;
  onClose: () => void;
  onScanSuccess: (scanResult: ScanResult) => void;
}) => {
  const { t } = useI18n();
  const [tab, setTab] = useState(isMobile && (isIOS || isAndroid) ? 1 : 0);

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      data-testid="scanCodeDialog"
      maxWidth="sm"
      title={t("datamatrix.dialogTitle")}
    >
      <>
        <Tabs
          value={tab}
          onChange={(_event: React.SyntheticEvent, newValue: number) => {
            setTab(newValue);
          }}
          variant="fullWidth"
        >
          <Tab label={t("datamatrix.barCodeScanner.tabTitle")} />
          <Tab label={t("datamatrix.camera.tabTitle")} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <BarCodePanel onScanSuccess={onScanSuccess} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <CameraPanel onScanSuccess={onScanSuccess} />
        </TabPanel>
      </>
    </StyledDialog>
  );
};

const DatamatrixButton = ({ isOpen = false }: { isOpen?: boolean }) => {
  const { t } = useI18n();
  const [open, setOpen] = useState(isOpen);
  const [scanResult, setScanResult] = useState<ScanResult | undefined>(undefined);
  const _vaccinationSchema = getVaccinationSchema();
  const { setValues } = useFormikContext<Asserts<typeof _vaccinationSchema>>();
  const onScanSuccess = (scanResult: ScanResult) => {
    setScanResult(scanResult);
    setOpen(false);
    if (scanResult.vaccine) {
      //Update formik values
      setValues((prevState) => {
        return {
          ...prevState,
          vaccineId: scanResult.vaccine?.id || "",
          expirationDate: scanResult.parsedDatamatrix.expirationDate ?? undefined,
          batchNumber: scanResult.parsedDatamatrix.batchNumber ?? "",
          serialNumber: scanResult.parsedDatamatrix.serialNumber ?? "",
          certificationMethod: CertificationMethodEnum.Execution,
        };
      });
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<SearchIcon />}
        data-testid={`scanDatamatrixDialogButton`}
        size="small"
        onClick={() => setOpen(true)}
      >
        {t("datamatrix.scanCta")}
      </Button>
      <ScanCodeDialog open={open} onClose={() => setOpen(false)} onScanSuccess={onScanSuccess} />
      <Snackbar
        open={scanResult && !!scanResult.parsedDatamatrix}
        autoHideDuration={6000}
        onClose={() => setScanResult(undefined)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ ml: "150px" }}
      >
        {scanResult && (
          <Alert severity={scanResult && !!scanResult.vaccine ? "success" : "warning"}>
            {scanResult && !!scanResult.vaccine ? t("datamatrix.successToast") : t("datamatrix.notFoundToast")}
          </Alert>
        )}
      </Snackbar>
    </>
  );
};

const DeviceSelector = ({
  onChangeCallback,
  devices,
}: {
  onChangeCallback: (e: SelectChangeEvent<number | string | undefined>) => void;
  devices: MediaDeviceInfo[];
}) => {
  return (
    <StyledSelect fullWidth onChange={onChangeCallback} name={"deviceSelector"}>
      {devices.map((device, index) => (
        <MenuItem key={index} value={index}>
          <Typography sx={{ whiteSpace: "wrap" }}>{device.label}</Typography>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default DatamatrixButton;
