import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import { Box, FormControl, FormHelperText, InputLabel, alpha } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@syadem/sad-ui";
import { FormikErrors } from "formik";
import { ComponentProps, useEffect, useState } from "react";
import { dayjs } from "../../../utils/dayjs";
import { TranslateFunction } from "../../../utils/formUtils";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { StyledInput } from "./StyledInput";

interface StyledDatePickerProps extends ComponentProps<DatePicker> {
  error?: boolean;
  errorMessage?: FormikErrors<Date>;
  lineThrough?: boolean;
  required?: boolean;
  touched?: boolean;
  translateErrors?: TranslateFunction;
  warningText?: string;
  open?: boolean;
}

const StyledDatepicker = ({
  id,
  name,
  label,
  placeholder,
  value,
  onChange,
  error,
  errorMessage,
  testId = "",
  minDate,
  maxDate,
  disabled,
  readOnly,
  fullWidth,
  lineThrough = false,
  required,
  touched,
  translateErrors,
  warningText,
  open,
}: StyledDatePickerProps) => {
  const { locale } = useI18n();
  const [openDatepicker, setOpenDatepicker] = useState(open || false);
  useEffect(() => {
    if (open !== undefined) setOpenDatepicker(open);
  }, [open]);
  return !readOnly ? (
    <FormControl
      variant="standard"
      fullWidth={!!fullWidth}
      error={error}
      disabled={disabled}
      required={required}
      data-testid={`${testId || name || id}FormControl`}
    >
      {label ? (
        <InputLabel
          shrink
          htmlFor={`${id || name}Input`}
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiInputLabel-asterisk": {
              color: theme.palette.error[500],
            },
            textDecoration: lineThrough ? "line-through" : "none",
          }}
          data-testid={`${testId || name || id}Label`}
        >
          {label}
        </InputLabel>
      ) : null}
      <Box
        sx={{
          marginTop: 3,
          ".MuiFormControl-root": {
            width: fullWidth ? "100%" : "auto",
            "&.Mui-error": {
              borderColor: theme.palette.error[600],
              background: theme.palette.error[100],
              color: theme.palette.error[400],

              ".MuiInputBase-root.MuiOutlinedInput-root": {
                color: theme.palette.error[400],
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  boxShadow: `${alpha(theme.palette.error[600] as string, 0.25)} 0 0 0 0.2rem`,
                  borderColor: theme.palette.error[600],
                  borderRadius: "4px",
                },
                svg: {
                  color: theme.palette.error[400],
                },
              },
            },
          },
          ".MuiInputBase-root.MuiOutlinedInput-root": {
            borderRadius: "4px",
            fontSize: "14px",
            borderColor: "#ced4da",
          },
          ".MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
          },
          ".MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
            borderWidth: "1px",
          },
          ".MuiInputBase-input.MuiOutlinedInput-input": {
            textDecoration: lineThrough ? "line-through" : "none",
            paddingY: "10px",
            paddingLeft: "16px",
          },
        }}
        data-testid={testId || name || id}
      >
        <DesktopDatePicker
          className={error ? "Mui-error" : ""}
          value={value ? dayjs(value) : null}
          onChange={(date) => {
            onChange(date ? (date.isUTC() ? date.endOf("day") : date?.endOf("day").utc()) : (date ?? undefined));
          }}
          minDate={minDate ? minDate.startOf("day").utc() : undefined}
          maxDate={maxDate ? maxDate.endOf("day").utc() : undefined}
          slotProps={{
            actionBar: {
              actions: ["clear", "today"],
            },
            textField: {
              error: Boolean(error),
              inputProps: {
                name: name,
                placeholder: placeholder,
                id: `${testId || name || id}Input`,
                "data-testid": `${testId || name || id}Input`,
              },
            },
          }}
          disabled={disabled}
          open={openDatepicker}
          onOpen={() => setOpenDatepicker(true)}
          onClose={() => setOpenDatepicker(false)}
        />
      </Box>
      {error && errorMessage && touched && translateErrors ? (
        <FormHelperText data-testid={`${testId || name || id}Error`}>
          {translateErrors({ key: "date", ...errorMessage })}
        </FormHelperText>
      ) : warningText ? (
        <FormHelperText
          data-testid={`${testId || name || id}Warning`}
          sx={{
            backgroundColor: theme.palette.warning[100],
            color: theme.palette.warning[800],
            px: 1,
            fontWeight: 500,
            display: "inline-flex",
            alignItems: "center",
            lineHeight: "24px",
            mx: 0,
          }}
          variant="filled"
        >
          <GppMaybeOutlinedIcon fontSize="small" sx={{ color: theme.palette.warning[800], mr: 1 }} />
          {warningText}
        </FormHelperText>
      ) : null}
    </FormControl>
  ) : (
    <StyledInput
      id={id || name}
      fullWidth={!!fullWidth}
      size="small"
      value={value ? dayjs(value).locale(locale).format("L") : ""}
      label={label}
      placeholder={placeholder}
      readOnly
      name={name}
      disabled={disabled}
      testId={testId || name || id}
      lineThrough={lineThrough}
    />
  );
};

export default StyledDatepicker;
