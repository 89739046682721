import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  Grid2,
  Input,
  LinearProgress,
  Paper,
  Radio,
  Typography,
} from "@mui/material";
import { CertificationMethodEnum, ModelError, ResponseError } from "@syadem/kairos-pro-js";
import { Formik, useFormikContext } from "formik";
import { orderBy } from "lodash-es";
import { ReactNode, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Asserts } from "yup";
import { Country } from "../../../domain/country";
import { formatDateForApi } from "../../../utils/date";
import { dayjs } from "../../../utils/dayjs";
import { TranslateFunction, createTranslateFn } from "../../../utils/formUtils";
import { compareNormalizedString } from "../../../utils/string";
import yup from "../../../utils/yup";
import { AddVaccinationsList } from "../../components/AddVaccinationsList";
import DatamatrixButton from "../../components/Datamatrix";
import FormVaccinesAutocomplete from "../../components/FormVaccinesAutocomplete";
import StyledAutoComplete from "../../components/mui/StyledAutoComplete";
import { CancelLinkButton } from "../../components/mui/StyledButtons";
import StyledDatepicker from "../../components/mui/StyledDatepicker";
import StyledDialog from "../../components/mui/StyledDialog";
import { StyledRadioGroup } from "../../components/mui/StyledRadioGroup";
import { useFetchHealthRecord } from "../../hooks/useFetchHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { useVaccinesWithDiseases } from "../../hooks/useVaccinesWithDiseases";
import { theme } from "../../layout/Theme";
import { BoosterForm } from "./VaccinationActForm";
import { useApis } from "../../providers/Dependencies";
import { useAppContext } from "../../hooks/useAppContext";
import { useCountryConfig } from "../../hooks/useCountryConfig";
import { useHealthRecord } from "../../../store";

export const getVaccinationsSchema = (birthDate?: Date) => {
  return yup.object({
    performedOn: yup
      .date()
      .min(
        dayjs
          .utc(birthDate || "1900-01-01")
          .startOf("day")
          .toDate(),
      )
      .max(dayjs().endOf("day").utc().toDate())
      .required(),
    vaccineId: yup.string().required(),
    booster: yup.boolean(),
    certificationMethod: yup.mixed<CertificationMethodEnum>().oneOf(Object.values(CertificationMethodEnum)),
    countryCode: yup.string().required(),
  });
};

function AddVaccinations() {
  const { id: healthRecordId } = useParams() as {
    id: string;
  };
  const { t, getObject } = useI18n();
  const translateErrors: TranslateFunction = useMemo(() => createTranslateFn(t), [t]);
  useFetchHealthRecord(healthRecordId);
  const healthRecord = useHealthRecord(healthRecordId);
  const { isLoading: isLoadingVaccinesWithDiseases } = useVaccinesWithDiseases();
  const [countryCodeInput, setCountryCodeInput] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>(undefined);
  const vaccinationsSchema = getVaccinationsSchema(healthRecord?.birthDate);
  const [vaccinations, setVaccinations] = useState<Asserts<typeof vaccinationsSchema>[] | undefined>(undefined);
  const [isSavingAll, setIsSavingAll] = useState(false);
  const [openSameVaccinationDialog, setOpenSameVaccinationDialog] = useState(false);
  // Submission
  const { pathname } = useLocation();
  const countries = useMemo(() => {
    return Object.entries(getObject("countries")).reduce(
      (acc: Country[], [key, value]) => [...acc, { code: key, name: value }],
      [],
    );
  }, [getObject]);
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const navigate = useNavigate();
  const { defaultCountryCode } = useCountryConfig();

  const saveAll = () => {
    setIsSavingAll(true);
    setErrorMessage(undefined);
    const promises = vaccinations?.map((vaccination) => {
      // TODO: Wrap this inside a service
      if (organizationId && teamId) {
        return apis.team.vaccinationActApi.createVaccinationAct(organizationId, teamId, healthRecordId, {
          vaccinationAct: {
            booster: vaccination.booster,
            countryCode: vaccination.countryCode,
            vaccineId: vaccination.vaccineId,
            performedOn: formatDateForApi(vaccination.performedOn),
            certificationMethod:
              vaccination?.certificationMethod === null
                ? CertificationMethodEnum.NotValidated
                : vaccination?.certificationMethod || CertificationMethodEnum.Execution,
          },
        });
      } else {
        return apis.pro.vaccinationActApi.createVaccinationAct(healthRecordId, {
          vaccinationAct: {
            booster: vaccination.booster,
            countryCode: vaccination.countryCode,
            vaccineId: vaccination.vaccineId,
            performedOn: formatDateForApi(vaccination.performedOn),
            certificationMethod:
              vaccination?.certificationMethod === null
                ? CertificationMethodEnum.NotValidated
                : vaccination?.certificationMethod || CertificationMethodEnum.Execution,
          },
        });
      }
    });
    Promise.all(promises || [])
      .then(() => {
        setIsSavingAll(false);
        setVaccinations(undefined);
        navigate(`..?updated=true`);
      })
      .catch(async (e) => {
        setIsSavingAll(false);
        if ((e instanceof Response && e.status === 400) || (e instanceof ResponseError && e.response.status === 400)) {
          const response = e instanceof Response ? e : e.response;
          const code = ((await response.json()) as ModelError).error.code;

          switch (code) {
            case "act_cannot_be_performed_in_the_future_or_before_patient_birth_date":
              setErrorMessage(t("vaccines.error_messages.error_1"));
              break;
            case "act_cannot_be_performed_after_vaccine_expiration_date":
              setErrorMessage(t("vaccines.error_messages.error_2"));
              break;
            case "incorrect_injection_method":
              setErrorMessage(t("vaccines.error_messages.error_3"));
              break;
            case "incorrect_injection_location":
              setErrorMessage(t("vaccines.error_messages.error_4"));
              break;
          }
        } else {
          setErrorMessage(t("common.alerts.alert_notification"));
        }
      });
  };

  return (
    <>
      {isLoadingVaccinesWithDiseases && !healthRecord && <LinearProgress variant="query" />}
      {!isLoadingVaccinesWithDiseases && (
        <Paper
          sx={{ overflow: "hidden", border: `solid 1px ${theme.palette.neutral[200]}`, p: { xs: 2, md: 4 } }}
          elevation={0}
        >
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
            <Box flex={1}>
              <Formik
                initialValues={{
                  vaccineId: "",
                  countryCode: defaultCountryCode,
                  performedOn: dayjs().startOf("day").utc().toDate(),
                  certificationMethod: CertificationMethodEnum.Proof,
                  booster: false,
                }}
                validationSchema={vaccinationsSchema}
                onSubmit={async (values) => {
                  const vaccinationActs = healthRecord?.vaccinationActs || [];
                  const vaccinationAct = vaccinationActs.find((vaccinationAct) => {
                    return (
                      dayjs(vaccinationAct.performedOn).isSame(values.performedOn, "day") &&
                      vaccinationAct.vaccineId === values.vaccineId
                    );
                  });
                  if (vaccinationAct) {
                    setOpenSameVaccinationDialog(true);
                  } else {
                    setVaccinations((prevState) => [...(prevState || []), values]);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  getFieldProps,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }) => (
                  <>
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                      <Input
                        type="text"
                        id="PreventChromeAutocomplete"
                        name="PreventChromeAutocomplete"
                        autoComplete="address-level4"
                        sx={{ display: "none" }}
                      />

                      <Grid2 container rowSpacing={2} justifyContent="space-between">
                        <Grid2 size={12} container justifyContent="space-between" marginBottom={2}>
                          <Grid2 container>
                            <Avatar
                              sx={{
                                background: theme.palette.primary[500],
                                color: theme.palette.primary[100],
                                mr: 2,
                              }}
                            >
                              1
                            </Avatar>
                            <Typography variant="h6">{t("vaccines.add_vaccination")}</Typography>
                          </Grid2>
                        </Grid2>
                        <Grid2
                          container
                          rowSpacing={2}
                          sx={{ border: `solid 1px ${theme.palette.neutral[200]}`, borderRadius: 1, p: 2, mt: 0 }}
                        >
                          <Grid2>
                            <DatamatrixButton />
                          </Grid2>
                          {errorMessage && (
                            <Grid2 size={12}>
                              <Alert severity="error">{errorMessage}</Alert>
                            </Grid2>
                          )}
                          <Grid2 size={12}>
                            <StyledDatepicker
                              {...getFieldProps("performedOn")}
                              placeholder={t("common.dates.datePlaceholder")}
                              label={t("vaccines.date_act")}
                              testId="performedOn"
                              required
                              // fullWidth
                              minDate={dayjs(healthRecord?.birthDate)}
                              maxDate={dayjs()}
                              onChange={(date) => {
                                setFieldTouched("performedOn", true, false);
                                setFieldValue("performedOn", date ? date.toDate() : "");
                              }}
                              error={touched.performedOn && !!errors.performedOn}
                              errorMessage={errors.performedOn}
                              translateErrors={translateErrors}
                              touched={!!touched.performedOn}
                            />
                          </Grid2>
                          <Grid2 size={12}>
                            <StyledAutoComplete
                              id="countryCode"
                              label={t("common.user_infos.country")}
                              error={touched.countryCode && !!errors.countryCode}
                              errorMessage={errors.countryCode}
                              touched={touched.countryCode}
                              translateErrors={translateErrors}
                              fullWidth
                              placeholder="ex : France"
                              required
                              options={countries}
                              filterOptions={(x) =>
                                x.filter((e) => compareNormalizedString(e.name, countryCodeInput || ""))
                              }
                              getOptionLabel={(country: Country) => country.name || ""}
                              onOpen={() => setFieldTouched("countryCode", true)}
                              value={countries.find((country) => country.code == values.countryCode) || null}
                              onInputChange={(_event, newCountryCode, reason) => {
                                if (reason === "input") {
                                  setCountryCodeInput(newCountryCode);
                                }
                              }}
                              onChange={(_event, newCountryCode) => {
                                setFieldTouched("countryCode", true);
                                setFieldValue("countryCode", newCountryCode?.code);
                                if (!newCountryCode) {
                                  setCountryCodeInput(undefined);
                                  setFieldValue("countryCode", "");
                                }
                              }}
                            />
                          </Grid2>
                          <Grid2 size={12} container>
                            <FormVaccinesAutocomplete />
                          </Grid2>
                          <Grid2 size={12} container columnSpacing={4} rowSpacing={2}>
                            <BoosterForm vaccinationDate={values.performedOn} birthDate={healthRecord?.birthDate} />
                          </Grid2>
                          <Grid2 size={12}>
                            <StyledRadioGroup
                              label="Validation"
                              fullWidth
                              value={values?.certificationMethod}
                              onChange={({ target: { value } }) => {
                                setFieldValue("certificationMethod", value);
                              }}
                              groupName="certificationMethod"
                            >
                              <FormControlLabel
                                value="not_validated"
                                control={<Radio size="small" data-testid="notValidatedRadio" />}
                                label={
                                  <Typography variant="body2">{t("vaccines.confirmation.not_validated")}</Typography>
                                }
                              />
                              <FormControlLabel
                                value="proof"
                                control={<Radio size="small" data-testid="validateRadio" />}
                                label={<Typography variant="body2">{t("vaccines.confirmation.validate")}</Typography>}
                              />
                              <FormControlLabel
                                value="execution"
                                control={<Radio size="small" data-testid="doValidateRadio" />}
                                label={
                                  <Typography variant="body2">{t("vaccines.confirmation.do_validate")}</Typography>
                                }
                              />
                            </StyledRadioGroup>
                          </Grid2>
                          <Grid2 size={12} container justifyContent="flex-end">
                            <LoadingButton
                              variant="contained"
                              disableElevation
                              type="submit"
                              loading={isSubmitting}
                              startIcon={<AddIcon />}
                              data-testid="submit"
                            >
                              {t("common.cta.add")}
                            </LoadingButton>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                    </form>
                    <AddSameVaccineDialog
                      open={openSameVaccinationDialog}
                      onClose={() => setOpenSameVaccinationDialog(false)}
                      setVaccinations={setVaccinations}
                    />
                  </>
                )}
              </Formik>
            </Box>
            {/* <Divider sx={{ width: "100%", my: 3 }} /> */}
            <Box flex={1} ml={{ size: 0, md: 3 }} mt={{ size: 4, md: 0 }}>
              <Grid2 container>
                <Avatar
                  sx={{
                    background: theme.palette.primary[500],
                    color: theme.palette.primary[100],
                    mr: 2,
                  }}
                >
                  2
                </Avatar>
                <Typography variant="h6" marginBottom={2}>
                  {t("vaccines.addVaccinations.title")}
                </Typography>
              </Grid2>
              {vaccinations && vaccinations?.length > 0 ? (
                <AddVaccinationsList
                  vaccinationActs={orderBy(vaccinations, (vaccinationAct) => vaccinationAct.performedOn, "asc")}
                  birthDate={healthRecord?.birthDate as Date}
                  deleteVaccinationAct={(index: number) => {
                    setVaccinations((prevState) => prevState?.filter((_, i) => i !== index));
                  }}
                />
              ) : (
                <Paper
                  elevation={0}
                  sx={{
                    background: theme.palette.neutral[100],
                    border: `solid 1px ${theme.palette.neutral[200]}`,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 3,
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    {t("vaccines.addVaccinations.no_vaccines_added")}
                  </Typography>
                </Paper>
              )}
            </Box>
          </Box>
          <Grid2 container rowSpacing={2} justifyContent="space-between" marginTop={4}>
            <Grid2 size={12} container justifyContent="space-between">
              <Box sx={{ display: "inline-flex" }}>
                <CancelLinkButton
                  component={Link}
                  to={`${pathname.substring(0, pathname.lastIndexOf("/health-records") + 15)}/${healthRecordId}`}
                  variant="contained"
                  disableElevation
                >
                  {t("common.cta.cancel")}
                </CancelLinkButton>
              </Box>
              <LoadingButton
                variant="contained"
                disableElevation
                loading={isSavingAll}
                data-testid="saveVaccinations"
                disabled={!vaccinations || vaccinations?.length === 0}
                onClick={saveAll}
              >
                {t("common.cta.save")}
              </LoadingButton>
            </Grid2>
          </Grid2>
        </Paper>
      )}
    </>
  );
}

export default AddVaccinations;

const AddSameVaccineDialog = ({
  open,
  onClose,
  setVaccinations,
}: {
  open: boolean;
  onClose: () => void;
  setVaccinations: React.Dispatch<
    React.SetStateAction<
      | {
          certificationMethod: CertificationMethodEnum | undefined;
          booster: boolean | undefined;
          vaccineId: string;
          countryCode: string;
          performedOn: Date;
        }[]
      | undefined
    >
  >;
}) => {
  const { t } = useI18n();
  const _vaccinationsSchema = getVaccinationsSchema();
  const { values } = useFormikContext<Asserts<typeof _vaccinationsSchema>>();

  const handleClose = () => {
    setVaccinations((prevState) => [...(prevState || []), values]);
    onClose();
  };

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      title={t("vaccines.addSimilarVaccination.title")}
      dialogActions={
        <Button onClick={handleClose} autoFocus variant="contained">
          {t("common.cta.add")}
        </Button>
      }
    >
      {t("vaccines.addSimilarVaccination.details")}
    </StyledDialog>
  );
};
