import { Box, Container } from "@mui/material";
import { ConditionProfile, ProfileActionType, ProfileState, reducer } from "@syadem/sad-ui";
import { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useFetchHealthRecord } from "../../../hooks/useFetchHealthRecord";
import { useI18n } from "../../../hooks/useI18n";
import { useProfilQuestionnaire } from "../../../hooks/useProfilQuestionnaire";
import { Notes } from "../note/Notes";
import ProfileConditions from "./ProfileConditions";
import ProfileQuestionnaire from "./ProfileQuestionnaire";
import { useApis } from "../../../providers/Dependencies";
import { useAppContext } from "../../../hooks/useAppContext";
import { useHealthRecord } from "../../../../store";

export function Profile() {
  const { id } = useParams() as { id: string };
  useFetchHealthRecord(id);
  const healthRecord = useHealthRecord(id);
  const { questionnaireSections } = useProfilQuestionnaire(healthRecord);
  const { t } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const initialState: ProfileState = {
    breadcrumb: [
      {
        level: 0,
        sectionId: "root",
        name: t("profile.shortTitle"),
      },
    ],
    currentSectionId: "root",
    searchConditionTerm: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (questionnaireSections && healthRecord?.profile) {
      dispatch({
        type: ProfileActionType.LoadQuestionnaireAndConditions,
        payload: {
          sections: questionnaireSections,
          conditionsProfile: Object.entries(healthRecord.profile).reduce((acc: ConditionProfile[], [key, value]) => {
            acc.push({
              id: key,
              value: value.value,
            });
            return acc;
          }, []),
        },
      });
    }
  }, [healthRecord?.profile, questionnaireSections]);

  const setCondition = async (value: string | Date | number | boolean | null | undefined, conditionId: string) => {
    const newValue = value === "" ? null : value;

    // TODO: Wrap this inside a service
    if (organizationId && teamId) {
      return await apis.team.healthProfileApi.setCondition(organizationId, teamId, healthRecord?.id as string, {
        conditionId: conditionId,
        value: newValue,
      });
    } else {
      return await apis.pro.healthProfileApi.setCondition(healthRecord?.id as string, {
        conditionId: conditionId,
        value: newValue,
      });
    }
  };

  return (
    <Container maxWidth="lg" disableGutters>
      <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "900px" }}>
        <Notes />
        <Box sx={{ flex: 1 }}>
          <ProfileConditions state={state} dispatch={dispatch} />
        </Box>
        <ProfileQuestionnaire state={state} dispatch={dispatch} setCondition={setCondition} />
      </Box>
    </Container>
  );
}
