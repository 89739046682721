import { VaccinationActApiInterface } from "@syadem/kairos-pro-js";
import { AppStore } from "../store";

export class ProVaccinationActsQuery {
  constructor(
    private readonly store: AppStore,
    private readonly vaccinationActApi: VaccinationActApiInterface,
  ) {}

  async call(healthRecordId: string) {
    const { vaccinationActs } = await this.vaccinationActApi.allVaccinationActs(healthRecordId);

    this.store.setState((state) => {
      if (state.healthRecords && state.healthRecords[healthRecordId]) {
        state.healthRecords[healthRecordId].vaccinationActs = vaccinationActs;
      }
      return state;
    });
  }
}
