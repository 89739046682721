import { VaccinationActApiInterface } from "@syadem/kairos-team-js";
import { AppStore } from "../store";

export class TeamVaccinationActsQuery {
  constructor(
    private readonly store: AppStore,
    private readonly vaccinationActApi: VaccinationActApiInterface,
  ) {}

  async call(organizationId: string, teamId: string, healthRecordId: string) {
    const { vaccinationActs } = await this.vaccinationActApi.allVaccinationActs(organizationId, teamId, healthRecordId);

    this.store.setState((state) => {
      if (state.healthRecords) {
        state.healthRecords[healthRecordId].vaccinationActs = vaccinationActs;
      }
      return state;
    });
  }
}
