import { Daphne, Vaccine } from "@syadem/daphne-js";
import { Result } from "@zxing/library";
import { InterpolationOptions } from "node-polyglot";
import { dayjs } from "./dayjs";
import { I18nKey } from "../ui/providers/I18nProvider";

export interface ParsedDatamatrixRaw {
  gtin?: string;
  productionDate?: string;
  bestBeforeDate?: string;
  expirationDate?: string;
  batchNumber?: string;
  serialNumber?: string;
  preventionMethodId?: string;
  portugeseId?: string;
}

export interface ParsedDatamatrix
  extends Omit<ParsedDatamatrixRaw, "productionDate" | "bestBeforeDate" | "expirationDate"> {
  productionDate?: Date;
  bestBeforeDate?: Date;
  expirationDate?: Date;
}

export interface ScanResult {
  rawDatamatrix: string;
  parsedDatamatrix: ParsedDatamatrix;
  vaccine?: Vaccine;
}

export type ProcessDatamatrixCode = (
  result: Result | string,
  t: (phrase: I18nKey, options?: number | InterpolationOptions | undefined) => string,
) => ScanResult;

export const datamatrixProcessor = (daphne: Daphne): ProcessDatamatrixCode => {
  function processDatamatrixCode(
    result: Result | string,
    t: (phrase: I18nKey, options?: number | InterpolationOptions | undefined) => string,
  ): ScanResult {
    const datamatrixString = result.toString();
    const parsedDatamatrix = parseDatamatrixString(datamatrixString);

    if (!parsedDatamatrix) {
      throw new Error(t("datamatrix.scanError"));
    }

    if (!parsedDatamatrix.gtin) {
      return { parsedDatamatrix, rawDatamatrix: datamatrixString };
    }

    let vaccine = daphne.queries.lookupVaccineByCode(parsedDatamatrix.gtin);
    if (!vaccine?.id && parsedDatamatrix.gtin.startsWith("03400")) {
      vaccine = daphne.queries.lookupVaccineByCode(parsedDatamatrix.gtin.substring(1));
    }

    if (vaccine?.id) {
      return {
        parsedDatamatrix,
        vaccine,
        rawDatamatrix: datamatrixString,
      };
    }

    return { parsedDatamatrix, rawDatamatrix: datamatrixString };
  }
  return processDatamatrixCode;
};

export const parseDatamatrixString = (datamatrixString: string): ParsedDatamatrix | undefined => {
  const regExContainsGtin = /(?:01)(?<gtin>\d{14})/g;
  const matchesWithGtin = [...datamatrixString.matchAll(regExContainsGtin)];
  if (matchesWithGtin.length > 0) {
    const maybeParsedWithFixedRegexp = parseDatamatrixStringWithFixedRegexp(datamatrixString);
    if (maybeParsedWithFixedRegexp) {
      return processDatamatrixDates(maybeParsedWithFixedRegexp);
    }

    const maybeParsedWithDynamicRegexp = parseDatamatrixStringWithDynamicRegexp(datamatrixString);
    if (maybeParsedWithDynamicRegexp) {
      return processDatamatrixDates(maybeParsedWithDynamicRegexp);
    }
  }
};

const parseDatamatrixStringWithFixedRegexp = (datamatrixString: string): ParsedDatamatrixRaw | undefined => {
  let result;
  const parsingRegexes = [
    /^(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:21)(?<serialNumber>\w{1,20})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})$/g,
    /^(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:21)(?<serialNumber>\w{1,20})(?:.?)(?:710)(?<nhrn>\d+)(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})$/g,
    /^(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:10)(?<batchNumber>\w{1,20})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:21)(?<serialNumber>\w{1,20})$/g,
    /^(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})$/g,
    /^(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})(?:.?)(?:21)(?<serialNumber>\w{1,20})$/g,
    /^(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})(?:.?)(?:21)(?<serialNumber>\w{1,20})(?:.?)(?:714)(?<portugeseId>\d{7})$/g,
    /^(?:.?|.[a-zA-Z]{1}\d{1})(?:01)(?<gtin>\d{14})(?:.?)(?:11)(?<productionDate>\d{6})(?:.?)(?:17)(?<expirationDate>\d{4,6})(?:.?)(?:10)(?<batchNumber>\w{1,20})(?:.?)(?:21)(?<serialNumber>\w{1,20})$/g,
  ];

  for (const regex of parsingRegexes) {
    const match = regex.exec(datamatrixString);
    if (match) {
      result = match.groups;
      break;
    }
  }

  return result;
};

const parseDatamatrixStringWithDynamicRegexp = (datamatrixString: string) => {
  const regEx =
    /(?:01)(?<gtin>\d{14})|(?:11)(?<productionDate>\d{6})|(?:15)(?<bestBeforeDate>\d{6})|(?:17)(?<expirationDate>\d{6})|(?:10)(?<batchNumber>[a-zA-Z0-9-]{1,20})|(?:714)(?<portugeseId>\d{7})|(?:21)(?<serialNumber>[a-zA-Z0-9-]{1,20})/g;
  const matches = datamatrixString.matchAll(regEx);
  let data: ParsedDatamatrixRaw = {};

  for (const match of matches) {
    const currentGroups = match.groups || {};
    data = { ...data, ...Object.keys(currentGroups).filter((key) => currentGroups[key]) };
  }

  if (Object.keys(data).length > 0) {
    return data;
  }
};

const processDatamatrixDates = (parsedDatamatrixRaw: ParsedDatamatrixRaw): ParsedDatamatrix => {
  type DateKey = keyof Pick<ParsedDatamatrixRaw, "productionDate" | "bestBeforeDate" | "expirationDate">;
  const dateKeys: DateKey[] = ["productionDate", "bestBeforeDate", "expirationDate"];

  const parsedDatamatrix = {
    ...parsedDatamatrixRaw,
    productionDate: undefined,
    bestBeforeDate: undefined,
    expirationDate: undefined,
  };

  return dateKeys.reduce((outputObject: ParsedDatamatrix, key: DateKey) => {
    if (parsedDatamatrixRaw[key]) {
      outputObject[key] = maybeConvertToDate(parsedDatamatrixRaw[key]);
    }
    return outputObject;
  }, parsedDatamatrix);
};

const maybeConvertToDate = (rawDate?: string): Date | undefined => {
  if (rawDate && rawDate.length === 6) {
    const day = rawDate.slice(4);
    const date = dayjs.utc(rawDate, "YYMMDD");

    if (day === "00") {
      return date.endOf("month").toDate();
    }
    return date.toDate();
  }
};
